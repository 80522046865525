import React, { Component } from 'react'
import { StaticQuery, graphql, Link } from 'gatsby'
import { StyleSheet, css } from 'aphrodite'
import Scroll from 'react-scroll'
import moment from 'moment'
import ReactMarkdown from 'react-markdown'
import Button from '@material-ui/core/Button'
import cn from 'classnames'
import AltertineLayout from '../layouts/AltertineLayout'

import { getLanguage } from '../layouts/Languages'
import i18next from '../../config/i18n/context'
import Classes from '../../styles/classes'

import PageLink from '../Link'
import pagesInfos from '../../config/i18n/pagesInfos'

class IndexPage extends Component {
  componentDidMount() {
    Scroll.scrollSpy.update()

    let hash = window.location.hash
    hash = hash.replace('#', '')

    if (hash && hash != '') {
      console.log(`hash : ${hash}`)
      Scroll.scroller.scrollTo(hash)
    }
  }

  render() {
    const lang =
      i18next.language ||
      (typeof window !== 'undefined' && window.localStorage.i18nextLng)
    console.log('language home', lang)
    return (
      <StaticQuery
        query={graphql`
          query TeamPageQuery {
            fr: allContentfulArticles(
              filter: { node_locale: { eq: "fr" } }
              sort: { fields: [createdAt], order: ASC }
            ) {
              edges {
                node {
                  id
                  titre
                  createdAt
                  permalink
                  introduction {
                    introduction
                  }
                  image {
                    fixed(width: 500) {
                      ...GatsbyContentfulFixed_noBase64
                    }
                  }
                  auteur {
                    nom
                    prenom
                  }
                }
              }
            }
            en: allContentfulArticles(
              filter: { node_locale: { eq: "en-US" } }
              sort: { fields: [createdAt], order: ASC }
            ) {
              edges {
                node {
                  id
                  titre
                  createdAt
                  permalink
                  introduction {
                    introduction
                  }
                  image {
                    fixed(width: 500) {
                      ...GatsbyContentfulFixed_noBase64
                    }
                  }
                  auteur {
                    nom
                    prenom
                  }
                }
              }
            }
          }
        `}
        render={data => {
          const styles = StyleSheet.create({
            footer: {
              backgroundColor: '#002337'
            },
            button: {
              display: 'block',
              margin: '1em 0'
            },
            imageTeam: {
              float: 'left',
              display: 'block',
              height: 'auto',
              width: '200px',
              marginRight: '25px',
              overflow: 'hidden'
            },
            imageTeamImg: {
              height: 'auto',
              width: '200px'
            }
          })
          const breadcrumbLinks = [
            <PageLink page={pagesInfos.ComponentBlog}>Blog</PageLink>
          ]
          const Items = data[getLanguage()].edges.map(({ node }) => {
            return (
              <div className="blog-item" key={node.id}>
                <div
                  className="blog-img-1"
                  style={{ background: 'url(' + node.image.fixed.src + ')' }}
                />

                <div className="blog-text">
                  <div className="text-up">
                    <p>{moment(node.createdAt).format('DD/MM/YYYY')}</p>
                    <span>{node.auteur.prenom + ' ' + node.auteur.nom}</span>
                  </div>

                  <div className="blog-text-content">
                    <Link to={node.permalink} title={node.titre}>
                      <h3>{node.titre}</h3>
                    </Link>
                    <p>
                      <ReactMarkdown source={node.introduction.introduction} />
                    </p>

                    <div className="button">
                      <Link to={node.permalink} title={node.titre}>
                        <Button className={css(Classes.buttonPrimary)}>
                          View more
                        </Button>
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
            )
          })
          return (
            <AltertineLayout
              title="Team"
              image="https://storage.googleapis.com/innobelge-websites.appspot.com/sites/innobelge/images/bg.jpg"
              breadcrumbLinks={breadcrumbLinks}
            >
              <section className="about about-page section-padding">
                <div className="container">
                  <div className="about-items">
                    <div className="row">
                      <div className="col-lg-4 col-md-4 col-sm-12 col-xs-12">
                        <div className="about-item">
                          <div className="item-icon">
                            <span className="icon icon-nature" />
                          </div>
                          <div className="text">
                            <h3>Flexibility</h3>
                            <p>
                              Lorem Ipsum is simply dummy text of the printing
                              and typesetting industry. Lorem Ipsum has been the
                              industry's standard dummy text ever since the
                              1500s, when an unknown printer took a galley of
                              type.
                            </p>
                          </div>
                        </div>
                      </div>

                      <div className="col-lg-4 col-md-4 col-sm-12 col-xs-12">
                        <div className="about-item">
                          <div className="item-icon">
                            <span className="icon icon-user" />
                          </div>

                          <div className="text">
                            <h3>Transparency</h3>
                            <p>
                              Lorem Ipsum is simply dummy text of the printing
                              and typesetting industry. Lorem Ipsum has been the
                              industry's standard dummy text ever since the
                              1500s, when an unknown printer took a galley of
                              type.
                            </p>
                          </div>
                        </div>
                      </div>

                      <div className="col-lg-4 col-md-4 col-sm-12 col-xs-12">
                        <div className="about-item">
                          <div className="item-icon">
                            <span className="icon icon-mobile" />
                          </div>

                          <div className="text">
                            <h3>Fully Responsive</h3>
                            <p>
                              Lorem Ipsum is simply dummy text of the printing
                              and typesetting industry. Lorem Ipsum has been the
                              industry's standard dummy text ever since the
                              1500s, when an unknown printer took a galley of
                              type.
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </section>

              <section className="about-info section-padding">
                <div className="container">
                  <div className="row">
                    <div className="col-lg-6 col-md-6 col-sm-12 col-xs-12">
                      <div className="about-info-img" />
                    </div>

                    <div className="info-float col-lg-6 col-md-6 col-sm-12 col-xs-12 clearfix">
                      <div className="info-text">
                        <div className="about-info-text">
                          <div className="title">
                            <span>About us</span>
                            <h2>
                              We Will Plan, Design, Developing & Maintain
                              Project
                            </h2>
                          </div>
                          <p>
                            Lorem Ipsum is simply dummy text of the printing and
                            typesetting industry. Lorem Ipsum has been the
                            industry's standard dummy text ever since the 1500s,
                            when an unknown printer took a galley of type.
                          </p>
                          <p>
                            It is a long established fact that a reader will be
                            distracted by the readable content of a page when
                            looking at its layout. The point of using Lorem
                            Ipsum is that it has a more-or-less normal
                            distribution of letters, as opposed to using
                            'Content here, content here', making it look like
                            readable English.
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </section>
              <section className="team section-padding">
                <div className="container">
                  <div className="row">
                    <div className="title">
                      <h2>Meet Our Team</h2>
                      <p>
                        Lorem Ipsum is simply dummy text of the printing and
                        typesetting industry.
                      </p>
                    </div>

                    <div className="team-items">
                      <div className="col-lg-6 col-md-6 col-sm-12 col-xs-12">
                        <div className="team-item">
                          <div className={css(styles.imageTeam)}>
                            <img
                              className={css(styles.imageTeamImg)}
                              src="http://next.creamconsulting.com/wp-content/uploads/2019/01/DSC_0135-removebg.png"
                            />
                          </div>

                          <div className="team-text">
                            <span>Founder</span>
                            <h3>John Doe</h3>
                            <p>
                              Lorem Ipsum is simply dummy text of the printing
                              and typesetting industry. Lorem Ipsum has been the
                              industry's standard dummy.
                            </p>

                            <div className="social">
                              <ul>
                                <li>
                                  <a href="">
                                    <i className="fab fa-facebook-f" />
                                  </a>
                                </li>
                                <li>
                                  <a href="">
                                    <i className="fab fa-behance" />
                                  </a>
                                </li>
                                <li>
                                  <a href="">
                                    <i className="fab fa-youtube" />
                                  </a>
                                </li>
                                <li>
                                  <a href="">
                                    <i className="fab fa-dribbble" />
                                  </a>
                                </li>
                              </ul>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div className="col-lg-6 col-md-6 col-sm-12 col-xs-12">
                        <div className="team-item">
                          <div className="team-img">
                            <img
                              src="http://via.placeholder.com/200/4f87fb/fff"
                              alt="team"
                            />
                          </div>

                          <div className="team-text">
                            <span>Manager</span>
                            <h3>John Doe</h3>
                            <p>
                              Lorem Ipsum is simply dummy text of the printing
                              and typesetting industry. Lorem Ipsum has been the
                              industry's standard dummy.
                            </p>

                            <div className="social">
                              <ul>
                                <li>
                                  <a href="">
                                    <i className="fab fa-facebook-f" />
                                  </a>
                                </li>
                                <li>
                                  <a href="">
                                    <i className="fab fa-behance" />
                                  </a>
                                </li>
                                <li>
                                  <a href="">
                                    <i className="fab fa-youtube" />
                                  </a>
                                </li>
                                <li>
                                  <a href="">
                                    <i className="fab fa-dribbble" />
                                  </a>
                                </li>
                              </ul>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div className="col-lg-6 col-md-6 col-sm-12 col-xs-12">
                        <div className="team-item">
                          <div className="team-img">
                            <img
                              src="http://via.placeholder.com/200/4f87fb/fff"
                              alt="team"
                            />
                          </div>

                          <div className="team-text">
                            <span>Ui/Ux</span>
                            <h3>John Doe</h3>
                            <p>
                              Lorem Ipsum is simply dummy text of the printing
                              and typesetting industry. Lorem Ipsum has been the
                              industry's standard dummy.
                            </p>

                            <div className="social">
                              <ul>
                                <li>
                                  <a href="">
                                    <i className="fab fa-facebook-f" />
                                  </a>
                                </li>
                                <li>
                                  <a href="">
                                    <i className="fab fa-behance" />
                                  </a>
                                </li>
                                <li>
                                  <a href="">
                                    <i className="fab fa-youtube" />
                                  </a>
                                </li>
                                <li>
                                  <a href="">
                                    <i className="fab fa-dribbble" />
                                  </a>
                                </li>
                              </ul>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div className="col-lg-6 col-md-6 col-sm-12 col-xs-12">
                        <div className="team-item">
                          <div className="team-img">
                            <img
                              src="http://via.placeholder.com/200/4f87fb/fff"
                              alt="team"
                            />
                          </div>

                          <div className="team-text">
                            <span>Web Developer</span>
                            <h3>John Doe</h3>
                            <p>
                              Lorem Ipsum is simply dummy text of the printing
                              and typesetting industry. Lorem Ipsum has been the
                              industry's standard dummy.
                            </p>

                            <div className="social">
                              <ul>
                                <li>
                                  <a href="">
                                    <i className="fab fa-facebook-f" />
                                  </a>
                                </li>
                                <li>
                                  <a href="">
                                    <i className="fab fa-behance" />
                                  </a>
                                </li>
                                <li>
                                  <a href="">
                                    <i className="fab fa-youtube" />
                                  </a>
                                </li>
                                <li>
                                  <a href="">
                                    <i className="fab fa-dribbble" />
                                  </a>
                                </li>
                              </ul>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </section>
            </AltertineLayout>
          )
        }}
      />
    )
  }
}

export default IndexPage
